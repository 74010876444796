import { useFirebase } from "@/composables/firebase";
const { onAuthStateChanged, user, auth, customer, getUserIdToken, signIn } =
    useFirebase();
import { useCourse } from "@/composables/course";
const { courseData, subdomain } = useCourse();
const { useDefaultFetch } = useApiFetch();
const fetchCourseData = async (subdomain: string) => {
    const {
        execute: fetchCourseDataCall,
        data,
        isFetching: fetchCourseDataLoading,
        error,
    }: any = useDefaultFetch(`/chat/course/${subdomain}`).json();
    await fetchCourseDataCall();
    if (error.value) {
        console.error("Error fetching course data: ", error.value);
        const errorMessage =
            error.value?.message || "Error fetching course data";
        const errorBody =
            error.value?.response?.data?.message ||
            "Error fetching course data";

        const params = new URLSearchParams({
            error_message: errorMessage,
            error_body: errorBody,
        });
        return {
            error: true,
            errorMessage,
            errorBody,
            params,
        };
    }
    return data.value;
};
export default defineNuxtRouteMiddleware(async (to, from) => {
    if (import.meta.server) return;
    console.log("Getting course data in middleware");
    const host = window ? window.location?.hostname : "";
    console.log("Host: ", host);
    const domain = host.split(".")[0];
    console.log("Domain: ", domain);
    subdomain.value = from.query?.subdomain || domain || "";
    console.log("Subdomain from query: ", subdomain.value);
    if (subdomain.value === "localhost") {
        subdomain.value = "pinnaclegroupgolfcourses";
    }
    console.log("from: ", from);
    console.log("to: ", to);
    console.log("Subdomain: ", subdomain);
    try {
        const fetchedCourseData = await fetchCourseData(subdomain.value);
        if (!fetchedCourseData || fetchedCourseData.error) {
            return navigateTo(`/404?${fetchedCourseData.params.toString()}`);
        }
        console.log(
            "Course data fetched during middleware: ",
            fetchedCourseData,
        );
        courseData.value = fetchedCourseData;
    } catch (error) {
        console.error("Error fetching course data: ", error);
    }
});
